import {
    getActiveVertical,
    getSubscriptionDetails,
    prefix_to_object,
} from "../utils";
import { captureEvent } from "../Mixpanel";
import { getUser } from "../../contexts/UserContext";
import store from "@/core/stores";

export class SubscriptionTracker {
    state = store.getState();

    constructor(props) {
        this.props = props;
        this.currUser = getUser();
        this.vertical = this.state.selectedVertical.vertical;
    }

    book_a_call = (from) => {
        captureEvent("Book a Call Button Clicked", {
            from,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    exceed_job_limit_viewed = () => {
        captureEvent("Exceed Job Limit Notice Viewed", {
            ...prefix_to_object(this.currUser.company, "company_"),
            ...getActiveVertical(this.vertical),
        });
    };
    payment_form_viewed = (pkg) => {
        captureEvent("Payment Form Viewed", {
            ...prefix_to_object(this.currUser.company, "company_"),
            package: pkg,
        });
    };
    pricing_Info_viewed = (source) => {
        captureEvent("Pricing Info Viewed", {
            ...prefix_to_object(this.currUser.company, "company_"),
            source,
        });
    };
    pricing_Info_viewed_exited = () => {
        captureEvent("Pricing Info Viewed - Exited", {
            ...prefix_to_object(this.currUser.company, "company_"),
        });
    };
}
