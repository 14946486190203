import { RouteInterface } from "@/core/interfaces/route.interface";
import { lazy } from "react";

const ChangePhone = lazy(() => import("@/components/ChangePhone/ChangePhone"));
const VerticalsPage = lazy(
    () => import("@/pages/hp/components/Register/Verticals/VerticalsPage")
);
const Job = lazy(() => import("@/pages/hp/components/Register/Job/Job"));

const BuySubscription = lazy(
    () => import("@/pages/hp/Subscription/BuySubscription")
);
const VerifyPayment = lazy(
    () => import("@/pages/hp/Subscription/VerifyPayment")
);
const BlockSubscribePage = lazy(
    () => import("@/components/BlockSubscribePage")
);
const routes: Array<RouteInterface> = [
    {
        path: "change-phone",
        element: ChangePhone,
        name: "Change Phone",
    },
    {
        path: "hp/journey",
        element: VerticalsPage,
        name: "hp journey",
    },
    {
        path: "hp/journey/:vertical",
        element: Job,
        name: "Creation Job Journey",
    },
    {
        path: "subscribe",
        element: BlockSubscribePage,
    },
    {
        path: "hp/subscribe/:payment_intent_id",
        element: BuySubscription,
        isMenuItem: false,
        isVerticalRequired: true,
    },
    {
        path: "hp/subscribe/verify",
        element: VerifyPayment,
        isMenuItem: false,
        isVerticalRequired: true,
    },
];

const GlobalRoutes = routes;
export default GlobalRoutes;
