import store from "@/core/stores";
import Verticals from "@/core/enums/verticals.enum";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

export const marketing_v3_flag = () => {
    const state = store.getState();
    const isMarketingVertical =
        state.selectedVertical.vertical === Verticals.Marketing;
    const isMarketingV3Enabled = true;
    return isMarketingVertical && isMarketingV3Enabled;
};

export const marketing_v4_flag = () => {
    const isMarketingV4Enabled = useFeatureIsOn("marketing_v4_flag");
    return !isMarketingV4Enabled && marketing_v3_flag();
};

export const accounting_v2_flag = () => {
    const state = store.getState();
    const isAccountingVertical =
        state.selectedVertical.vertical === Verticals.Accounting;
    return isAccountingVertical;
};
export const accounting_v3_flag = () => {
    const isAccountingV3Enabled = useFeatureIsOn("accounting_v3_flag");
    return !accounting_v2_flag() || isAccountingV3Enabled;
};

export const accounting_v4_flag = () => {
    let isAccountingV4Enabled = useFeatureIsOn("accounting_v4_flag");
    return !isAccountingV4Enabled && accounting_v2_flag();
};

export const showJobCardQualifiedCandidates = (subscribed) => {
    return subscribed
        ? useFeatureIsOn("subscribed_job_card_qualified_candidates_feature")
        : useFeatureIsOn("free_trial_job_card_qualified_candidates_feature");
};
