export type Package = {
    id: string;
    name_en: string;
    name_ar: string;
    tier: string;
    duration_months: number;
    monthly_price: number;
    monthly_discount: number;
    includes_en: string[];
    includes_ar: string[];
    is_popular: boolean;
    book_a_call: boolean;
    created_at: string;
    updated_at: string;
};

export type PackageDetails = {
    id: string;
    duration_months: number;
    monthly_price: string;
    monthly_discount: string | null;
    discountedPrice: number | string;
    name_en: string;
    name_ar: string;
    includes_en: string[];
    includes_ar: string[];
    is_popular: boolean;
};

export type SubscriptionData = {
    tabText: string;
    discountPercentage: number;
    id: string;
};

export enum PaymentTransactionMethod {
    MOYASAR = "MOYASAR",
}

export enum ProductSubscriptionType {
    HP_SUBSCRIPTION = "HP_SUBSCRIPTION",
    BOOTCAMP = "BOOTCAMP",
    CP_SUBSCRIPTION = "CP_SUBSCRIPTION",
}

export enum PaymentTransactionStatus {
    INITIATED = "initiated",
    PAID = "paid",
    FAILED = "failed",
    AUTHORIZED = "authorized",
    CAPTURED = "captured",
    REFUNDED = "refunded",
    VOIDED = "voided",
    VERIFIED = "verified",
}

export enum SubscriptionTiersChoices {
    FREE_TRIAL = "FREE_TRIAL",
    BASIC = "BASIC",
    PREMIUM = "PREMIUM",
    MONTHLY = "MONTHLY",
    QUARTERLY = "QUARTERLY",
    ANNUALLY = "ANNUALLY",
}
