import React, { FunctionComponent } from "react";
import { Typography, TypographyProps } from "@mui/material";

interface OwnProps extends TypographyProps {
    component?: "span";
}

type Props = OwnProps;

const ScalersTypography: FunctionComponent<Props> = (props) => {
    return <Typography component={props.component} {...props} />;
};

export default ScalersTypography;
