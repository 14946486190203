import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

type SnackbarState = {
    content: string | null;
    description: string | null;
    isOpen: boolean;
    severity: "success" | "error" | "warning" | "info";
};

const initialState: SnackbarState = {
    content: null,
    description: null,
    isOpen: false,
    severity: "success",
};

// Thunk to show the snackbar and hide it after 3000ms
export const showSnackbarWithTimeout = createAsyncThunk(
    "snackbar/showSnackbarWithTimeout",
    async (
        {
            content,
            description,
            severity,
        }: {
            content: string;
            description?: string;
            severity?: "success" | "error" | "warning" | "info";
        },
        { dispatch }
    ) => {
        // Show snackbar
        dispatch(showSnackbar({ content, description, severity }));

        // Wait 3 seconds before hiding
        await new Promise((resolve) => setTimeout(resolve, 3000));

        dispatch(hideSnackbar());
    }
);

const snackbarSlice = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        showSnackbar: (
            state,
            action: PayloadAction<{
                content: string;
                description?: string;
                severity?: "success" | "error" | "warning" | "info";
            }>
        ) => {
            state.content = action.payload.content;
            state.description = action.payload.description ?? null;
            state.severity = action.payload.severity ?? "success";
            state.isOpen = true;
        },

        hideSnackbar: (state) => {
            state.content = null;
            state.description = null;
            state.isOpen = false;
        },
    },
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
