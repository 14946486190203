import { RouteInterface } from "@/core/interfaces/route.interface";
import { lazy } from "react";
import { WorkOutlineOutlined } from "@material-ui/icons";
import { PeopleOutlineOutlined, RouteOutlined } from "@mui/icons-material";

const Marketing = lazy(() => import("@/pages/hp/Candidates"));
const CandidateNew = lazy(() => import("@/pages/hp/CandidateNew"));
const Settings = lazy(() => import("@/pages/hp/Settings"));
const V4JobsNew = lazy(() => import("@/pages/hp/V4JobsNew"));
const CreateJob = lazy(() => import("@/pages/hp/CreateExpansionJob"));
const NewPipeline = lazy(() => import("@/pages/hp/NewPipeline"));
const MarketingFeedback = lazy(() => import("@/pages/hp/MarketingFeedback"));
const ViewJobNew = lazy(() => import("@/pages/hp/ViewJobNew"));
const ViewJobWithMatching = lazy(
    () => import("@/pages/hp/ViewJobWithMatching")
);

const verticalId = 2;
const base = "hp/marketing";

const routes: Array<RouteInterface> = [
    {
        path: `${base}/jobs`,
        element: V4JobsNew,
        isMenuItem: true,
        name: "jobs",
        icon: WorkOutlineOutlined,
        isVerticalRequired: true,
        props: {
            endpoint: `job/v4/list?vertical_id=${verticalId}`,
        },
    },
    {
        path: `${base}/candidates`,
        element: Marketing,
        isMenuItem: true,
        name: "candidate-list",
        icon: PeopleOutlineOutlined,
        props: {
            isMarketing: true,
            open_jobs_endpoint: `job/open-jobs-list/?vertical_id=${verticalId}`,
        },
        isVerticalRequired: true,
    },
    {
        path: `${base}/auto-matching/:job_id`,
        element: ViewJobWithMatching,
        isVerticalRequired: false,
        props: {
            applicantUrl: "/applicant/marketing/get/",
        },
    },
    {
        path: `${base}/jobs/:job_id`,
        element: ViewJobNew,
        isVerticalRequired: false,
        props: {
            applicantUrl: "/applicant/marketing/get/",
        },
    },
    {
        path: `${base}/jobs/create_job`,
        element: CreateJob,
        isVerticalRequired: true,
        props: {},
    },
    {
        path: `${base}/jobs/duplicate_job/:job_id`,
        element: CreateJob,
        isVerticalRequired: true,
    },
    {
        path: `${base}/jobs/create_job/feedback`,
        element: MarketingFeedback,
        isVerticalRequired: true,
        props: {},
    },
    {
        path: `${base}/candidates/:candidateId`,
        element: CandidateNew,
        props: {
            applicantUrl: "/applicant/marketing/get/",
            open_jobs_endpoint: `job/open-jobs-list/?vertical_id=${verticalId}`,
        },
        isVerticalRequired: true,
    },
    {
        path: `${base}/pipeline`,
        element: NewPipeline,
        isMenuItem: true,
        name: "pipeline",
        icon: RouteOutlined,
        isVerticalRequired: true,
        props: {
            open_jobs_endpoint: `job/open-jobs-list/?vertical_id=${verticalId}`,
            vertical: "marketing",
            job_pipeline_base_url: "job/v3",
        },
    },
    {
        path: `${base}/settings`,
        element: Settings,
    },
    {
        path: "hp/marketing/*",
        element: Marketing, // Default route for unspecified HP paths
        isVerticalRequired: true,
    },
];

const MarketingHPRoutes = routes;
export default MarketingHPRoutes;
