export const JobCancellationStatus = {
    RE_OPENED: 1,
    CLOSED: 2,
};

export const JobStatus = {
    Opened: "Opened",
    Closed: "Closed",
    Paused: "Paused",
};

export const CandidateType = {
    GenericCandidate: "generic_candidate",
    Candidate: "candidate",
    Matched: "match",
};

export const PipelinePosition = {
    "-1": "PENDING",
    "0": "SHORTLISTED",
    "1": "INTERVIEW_SCHEDULED",
    "2": "INTERVIEW_COMPLETED",
    "3": "OFFER_SENT",
    "4": "HIRED",
};

export const PipelinePositionByName = {
    PENDING: -1,
    SHORTLISTED: 0,
    INTERVIEW_SCHEDULED: 1,
    INTERVIEW_COMPLETED: 2,
    OFFER_SENT: 3,
    HIRED: 4,
};

export const CandidateApplicationSource = {
    1: "CANDIDATE_LIST",
    2: "CANDIDATE_PAGE",
    3: "CURATED_LIST",
    4: "JOB_POST",
    5: "JOB_CURATIONS",
    6: "PREDEFINED_LIST",
    7: "MATCHED_PLATFORM",
    8: "MATCHED_SOURCED",
};

export const CandidateApplicationSourceByName = {
    CANDIDATE_LIST: 1,
    CANDIDATE_PAGE: 2,
    CURATED_LIST: 3,
    JOB_POST: 4,
    JOB_CURATIONS: 5,
    PREDEFINED_LIST: 6,
    MATCHED_PLATFORM: 7,
    MATCHED_SOURCED: 8,
    JOB_DISTRIBUTION: 9,
};

export const GenerateMatchingStatusEnum = {
    NO_GENERATION_REQUIRED: "NO_GENERATION_REQUIRED",
    GENERATION_REQUIRED: "GENERATION_REQUIRED",
    MAX_GENERATION_REACHED: "MAX_GENERATION_REACHED",
};

export const CandidateScreeningEnum = {
    NO_SCREEN_REQUIRED: "no_screen_required",
    SCREEN_REQUIRED: "screen_required",
    SCREEN_IN_PROGRESS: "screen_in_progress",
    SCREEN_REJECTED: "screen_rejected",
    SCREEN_ACCEPTED: "screen_accepted",
};

export const TypeOfCandidatesEnum = {
    Matched: "matched",
    Curated: "curated",
    Applied: "applied",
};

export const CandidateMatchStatusEnum = {
    PRE_INITIALIZED: "Pre-initialized",
    INITIALIZED: "Initialized",
    RE_INITIALIZED: "Re-initialized",
    RESULTS_READY: "Results Ready",
};

export const CandidateMatchedActionStatusEnum = {
    NO_MATCHED_CANDIDATES: "no_matched_candidates",
    NO_ACTION_TAKEN: "no_action_taken",
    ACTION_TAKEN: "action_taken",
};

export const NonArabicSpeakingPercentagePointsEnum = {
    "0-10%": 0,
    "11-30%": 1,
    "31-60%": 2,
    "More than 60%": 3,
};

export const InternalEnglishPercentagePointsEnum = {
    "0-10% is the level of English use": 0,
    "11-49% is the level of English use": 1,
    "50-80% is the level of English use": 2,
    "+80% is the level of English use": 3,
};

export const JobClosingReasonTypeEnum = {
    single_search: "single_search",
    multi_search: "multi_search",
    single_select: "single_select",
    multi_select: "multi_select",
    open_text: "open_text",
};

export const CandidateInterviewStatusEnum = {
    REQUEST_RECEIVED: 1,
    INTERVIEW_SCHEDULING: 2,
    REQUEST_DECLINED: 3,
    TIME_UNAVAILABLE: 4,
    REQUEST_EXPIRED: 5,
};

export const CandidateDisqualificationReasonTypeEnum = {
    multi_select: "multi_select",
    open_text: "open_text",
    no_further_options: "no_further_options",
};

export const JobTabsEnum = {
    Active: "active",
    Inactive: "inactive",
};

export const JobProgressEnum = {
    Empty: 0,
    Full: 100,
};
