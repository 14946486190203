import { Stack } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import ScalersTypography from "@/components/atoms/typography/typography";
import { useTranslation } from "react-i18next";
import "@/../static/css/appsubscription.scss";
import AppSubscriptionTabs from "./components/AppSubscriptionTabs";
import { Package, SubscriptionData } from "./constants";
import AppSubscriptionTabPanel from "./components/AppSubscriptionTabPanel";
import BookCallFooter from "./components/BookCallFooter";
import { humanReadableNumbers } from "@/utils/utilFunctions";
import axiosInstance from "@/../static/axios";
import CalendlyModal from "../Modals/CalendlyModal";
import { Factory, Pages } from "@/mixpanel-tracking/hp/factory";
import { SubscriptionTracker } from "@/mixpanel-tracking/hp/subscription-tracking";
import { getUser } from "@/contexts/UserContext";

function transformPackages(packages: Package[]) {
    return packages.map((pkg: Package) => {
        const {
            id,
            duration_months,
            monthly_price = 0,
            monthly_discount = 0,
        } = pkg;
        const percentageDiscount =
            monthly_discount > 0
                ? Math.round((monthly_discount / monthly_price) * 100)
                : null;

        return {
            id,
            tabText: duration_months,
            discountPercentage: percentageDiscount,
        };
    });
}

function transformPackagesContent(packages: Package[]) {
    return packages.map((pkg) => {
        const {
            id,
            duration_months,
            name_en,
            name_ar,
            includes_en,
            includes_ar,
            monthly_price = 0,
            monthly_discount = 0,
            is_popular,
        } = pkg;

        return {
            id,
            duration_months,
            name_en,
            name_ar,
            includes_en,
            includes_ar,
            discountedPrice: humanReadableNumbers(
                monthly_price - monthly_discount
            ),
            monthly_price: humanReadableNumbers(monthly_price),
            monthly_discount:
                monthly_discount > 0
                    ? humanReadableNumbers(monthly_discount)
                    : null,
            is_popular,
        };
    });
}

function AppSubscription({ from }: { from: string }) {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(1);
    const [subscriptionPlans, setSubscriptionPlans] = useState<Package[]>([]);
    const [calendlyModalOpen, setCalendlyModalOpen] = useState<boolean>(false);
    const currUser = getUser();

    const factory = new Factory({
        currUser,
    });
    const track = factory.get_tracker(
        Pages.SUBSCRIPTIONS
    ) as SubscriptionTracker;

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const subscriptionTabs = useMemo(
        () => transformPackages(subscriptionPlans),
        [subscriptionPlans]
    ) as unknown as SubscriptionData[];

    const subscriptionTabsContent = useMemo(
        () => transformPackagesContent(subscriptionPlans),
        [subscriptionPlans]
    );
    const getSubscriptionPlans = async () => {
        const res = await axiosInstance.get("/company/subscription-packages");
        if (res.data) {
            setSubscriptionPlans(res.data);
        }
    };
    const handleCalendlyModal = () => {
        track.book_a_call(from);
        setCalendlyModalOpen(true);
    };

    useEffect(() => {
        getSubscriptionPlans();
    }, []);

    return (
        <>
            <CalendlyModal
                source="Buy Subscription Modal"
                open={calendlyModalOpen}
                setOpen={setCalendlyModalOpen}
            />
            <Stack gap="4px" textAlign="center">
                <ScalersTypography
                    variant="subtitle1"
                    fontSize="18px"
                    fontWeight="700"
                    color="var(--black-500)"
                >
                    {t("app-subscription-modal.heading")}
                </ScalersTypography>
                <ScalersTypography
                    variant="subtitle1"
                    fontSize="15px"
                    fontWeight="500"
                    color="var(--black-200)"
                    dangerouslySetInnerHTML={{
                        __html: t("app-subscription-modal.subheading"),
                    }}
                />
                {subscriptionPlans.length === 0 ? (
                    <></>
                ) : (
                    <Stack className="subscription-tabs-container">
                        <AppSubscriptionTabs
                            subscriptionTabs={subscriptionTabs}
                            handleChange={handleChange}
                            value={value}
                        />
                        <AppSubscriptionTabPanel
                            value={value}
                            subscriptionTabsContent={subscriptionTabsContent}
                        />
                        <BookCallFooter
                            handleCalendlyModal={handleCalendlyModal}
                        />
                    </Stack>
                )}
            </Stack>
        </>
    );
}

export default AppSubscription;
