import { RouteInterface } from "@/core/interfaces/route.interface";
import { lazy } from "react";
import { fake_candidate_new } from "@/pages/hp/components/fakecandidatedata";
import { MessageOutlined, WorkOutlineOutlined } from "@material-ui/icons";
import { PeopleOutlineOutlined, RouteOutlined } from "@mui/icons-material";
const ErrorPage = lazy(() => import("@/components/molecules/ErrorPages/Error"));
const Candidates = lazy(() => import("@/pages/hp/Candidates"));
const CandidateNew = lazy(() => import("@/pages/hp/CandidateNew"));
const V4JobsNew = lazy(() => import("@/pages/hp/V4JobsNew"));
const CreateJobNew = lazy(() => import("@/pages/hp/CreateJobNew"));
const Settings = lazy(() => import("@/pages/hp/Settings"));
const NewPipeline = lazy(() => import("@/pages/hp/NewPipeline"));
const ViewJobWithMatching = lazy(
    () => import("@/pages/hp/ViewJobWithMatching")
);

const verticalId = 1;

const routes: Array<RouteInterface> = [
    {
        path: "hp/jobs",
        element: V4JobsNew,
        isMenuItem: true,
        name: "jobs",
        icon: WorkOutlineOutlined,
        isVerticalRequired: true,
        props: {
            endpoint: `job/v4/list?vertical_id=${verticalId}`,
        },
    },
    {
        path: "hp/candidates",
        element: Candidates,
        isMenuItem: true,
        name: "candidate-list",
        icon: PeopleOutlineOutlined,
        props: {
            open_jobs_endpoint: `job/open-jobs-list/?vertical_id=${verticalId}`,
        },
        isVerticalRequired: true,
    },
    {
        path: "hp/pipeline",
        element: NewPipeline,
        isMenuItem: true,
        name: "pipeline",
        icon: RouteOutlined,
        isVerticalRequired: true,
        props: {
            open_jobs_endpoint: `job/open-jobs-list/?vertical_id=${verticalId}`,
            vertical: "sales",
            job_pipeline_base_url: "job/v3",
        },
    },
    {
        path: "hp/messages/*",
        element: ErrorPage,
        isMenuItem: true,
        name: "messages",
        props: {
            applicantUrl: "/applicant/sales/get/",
        },
        icon: MessageOutlined,
    },
    {
        path: "hp/candidates/:candidateId",
        element: CandidateNew,
        isVerticalRequired: true,
        props: {
            applicantUrl: "/applicant/sales/get/",
            open_jobs_endpoint: `job/open-jobs-list/?vertical_id=${verticalId}`,
        },
    },
    {
        path: "hp/candidates/demo",
        element: CandidateNew, // Assuming demo uses the same component
        props: {
            generic: true,
            fakeCandidate: fake_candidate_new,
        },
    },
    {
        path: "hp/candidates/generic/:candidateId",
        element: CandidateNew, // Assuming generic uses the same component
        props: {
            generic: true,
        },
        isVerticalRequired: true,
    },
    {
        path: "hp/settings",
        element: Settings,
    },
    {
        path: "hp/jobs/:job_id",
        element: ViewJobWithMatching,
        isVerticalRequired: false,
        props: {
            applicantUrl: "/applicant/sales/get/",
        },
    },
    {
        path: "hp/jobs/create_job",
        element: CreateJobNew,
        isVerticalRequired: true,
    },
    {
        path: "hp/jobs/duplicate_job/:job_id",
        element: CreateJobNew,
        isVerticalRequired: true,
    },
    {
        path: "hp/*",
        element: Candidates, // Default route for unspecified HP paths
        isVerticalRequired: true,
        props: {
            open_jobs_endpoint: `job/open-jobs-list/?vertical_id=${verticalId}`,
        },
    },
];

const SalesHPRoutes = routes;
export default SalesHPRoutes;
