import { getUser } from "@/contexts/UserContext";
import Verticals from "@/core/enums/verticals.enum";
import { setSelectedVertical } from "@/core/stores/verticals/selected-vertical.store";
// import BookSubscriptionCall from "@/pages/hp/BookSubscriptionCall";
import singletonLocalStorage from "@/utils/localStoring";
import React, { useEffect } from "react";
import { RootStateType } from "@/core/stores/index";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
    getDefaultVertical,
    getPathForVertical,
    getVerticalFromPath,
} from "./verticals-config";

type Props = {
    isVerticalRequired?: boolean;
    expectedVertical: Verticals;
    children: React.ReactNode;
};

const RequireVerticalSubscription: React.FC<Props> = (props: Props) => {
    const {
        children,
        // expectedVertical,
        // isVerticalRequired,
    } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const currUser = getUser();
    const verticals = currUser?.company?.active_subscription?.verticals.map(
        (vertical) => vertical.id
    );

    const lastSelectedVertical = singletonLocalStorage.getItem("vertical");
    const selectedVertical = useSelector(
        (state: RootStateType) => state.selectedVertical.vertical
    );

    useEffect(() => {
        // Handle the first reach to the app
        if (!selectedVertical && lastSelectedVertical) {
            dispatch(setSelectedVertical(lastSelectedVertical));
            const expectedPageToVisit = getPathForVertical(
                lastSelectedVertical,
                location.pathname
            );
            navigate(expectedPageToVisit, { replace: true });
        }
    }, [
        dispatch,
        selectedVertical,
        lastSelectedVertical,
        location.pathname,
        navigate,
    ]);

    //Handling the case when no vertical was selected, but the user have access to one or more verticals
    if (!selectedVertical && verticals && verticals.length > 0) {
        const defaultVertical = getDefaultVertical(verticals);
        dispatch(setSelectedVertical(defaultVertical));
        const expectedPageToVisit = getPathForVertical(
            defaultVertical,
            location.pathname
        );
        return <Navigate to={expectedPageToVisit} replace />;
    }
    //Handling cases when the user is trying to access the wrong path based on their last selected vertical
    const currentPathVertical = getVerticalFromPath(location.pathname);
    if (
        selectedVertical &&
        currentPathVertical &&
        Number(currentPathVertical) !== selectedVertical
    ) {
        const expectedPageToVisit = getPathForVertical(
            selectedVertical,
            location.pathname
        );

        return <Navigate to={expectedPageToVisit} replace />;
    }

    return children;
};

export default RequireVerticalSubscription;
