import { captureEvent } from "../Mixpanel";
import { prefix_to_object } from "../utils";

export class JobDistributionHPTracking {
    constructor() {}

    job_sharing_requested = (job: object, from: string) => {
        captureEvent("Job Sharing Requested", {
            ...prefix_to_object(job, "job_"),
            from: from,
        });
    };

    share_link_copied = () => {
        captureEvent("Share Link Copied");
    };
}
