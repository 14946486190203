import React from "react";
import { useNavigate } from "react-router-dom";
import "../../static/css/subscriptionlabel.css";
import { FeatureFlag } from "@/feature-flag";
import { SubscriptionTiersChoices } from "./AppSubscription/constants";

const subscribedTiers = [
    SubscriptionTiersChoices.BASIC,
    SubscriptionTiersChoices.PREMIUM,
    SubscriptionTiersChoices.MONTHLY,
    SubscriptionTiersChoices.QUARTERLY,
    SubscriptionTiersChoices.ANNUALLY,
];

const nonSubscribedTiers = [SubscriptionTiersChoices.FREE_TRIAL];

export default function SubscriptionLabel(props) {
    const { currUser, t, mobile, clickable, style, isExpanded, isSideBar } =
        props;

    const navigate = useNavigate();

    const getSubscriptionTier = () => {
        if (FeatureFlag.enable_buy_subscription) {
            if (currUser.company) {
                if (
                    subscribedTiers.includes(
                        currUser.company.active_subscription?.tier
                    )
                ) {
                    return "subscribed";
                }
                if (
                    nonSubscribedTiers.includes(
                        currUser.company.active_subscription?.tier
                    )
                ) {
                    return "unsubscribed";
                }
            }
            return null;
        } else {
            if (currUser.company) {
                if (currUser.company.active_subscription?.tier === "BASIC") {
                    return "basic";
                }
                if (currUser.company.active_subscription?.tier === "PREMIUM") {
                    return isExpanded ? "premium" : "premium-short";
                }
                return "unsubscribed";
            }
            return null;
        }
    };

    const redirect = () => {
        navigate("/hp/settings", { state: { toTab: "account_details" } });
    };

    return (
        <div
            className={`${isSideBar ? "sub-label-side-bar" : "sub-label"} sub-label-${getSubscriptionTier()} ${mobile ? "sub-label-mobile" : ""} ${clickable ? "sub-label-clickable" : ""}`}
            style={style}
            onClick={clickable ? () => redirect() : null}
        >
            <span>{t(`subscription_tier.${getSubscriptionTier()}`)}</span>
        </div>
    );
}
