import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootStateOrAny } from "react-redux";
import { useDispatch } from "react-redux";
import { setUpgradeToUnlockModalActive } from "@/core/stores/general/general.store";
import { Box, Button, Modal, Stack } from "@mui/material";
import ScalersTypography from "../atoms/typography/typography";
import { useTranslation } from "react-i18next";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import CalendlyModal from "./CalendlyModal";
import { useNavigate } from "react-router-dom";
import { Factory, Pages } from "@/mixpanel-tracking/hp/factory";
import { SubscriptionTracker } from "@/mixpanel-tracking/hp/subscription-tracking";
import { getUser } from "@/contexts/UserContext";

function UpgradeToUnlockModal() {
    const [calendlyModalOpen, setCalendlyModalOpen] = useState<boolean>(false);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { upgradeToUnlockModalActive } = useSelector(
        (state: RootStateOrAny) => state.general
    );
    const dispatch = useDispatch();
    const currUser = getUser();

    const factory = new Factory({
        currUser,
    });
    const track = factory.get_tracker(
        Pages.SUBSCRIPTIONS
    ) as SubscriptionTracker;

    const handleAppSubscriptionModalClose = () => {
        dispatch(setUpgradeToUnlockModalActive(false));
    };

    const handleNavigateToJob = () => {
        handleAppSubscriptionModalClose();
        navigate("/hp/jobs");
    };

    const handleBookCall = () => {
        track.book_a_call("Exceeding Limit Pop up");
        handleAppSubscriptionModalClose();
        setCalendlyModalOpen(true);
    };

    return (
        <>
            <CalendlyModal
                source="Upgrade to Unlock Modal"
                open={calendlyModalOpen}
                setOpen={setCalendlyModalOpen}
            />
            <Modal
                className="upgrade-unlock-modal"
                open={upgradeToUnlockModalActive}
                onClose={handleAppSubscriptionModalClose}
            >
                <Stack className="modal-container">
                    <WorkOutlineIcon className="case-icon" />
                    <ScalersTypography
                        variant="subtitle1"
                        fontWeight="bold"
                        fontSize="19px"
                        color="var(--black-500)"
                    >
                        {t("upgrade-to-unlock.heading")}
                    </ScalersTypography>
                    <ScalersTypography
                        variant="body2"
                        fontWeight="400"
                        color="#7A7A7A"
                        fontSize="13px"
                    >
                        {t("upgrade-to-unlock.subheading")}
                    </ScalersTypography>
                    <Box className="button-container">
                        <Button
                            className="outlined-button"
                            onClick={handleNavigateToJob}
                        >
                            {t("upgrade-to-unlock.manage-cta")}
                        </Button>
                        <Button variant="contained" onClick={handleBookCall}>
                            {t("upgrade-to-unlock.book-cta")}
                        </Button>
                    </Box>
                </Stack>
            </Modal>
        </>
    );
}

export default UpgradeToUnlockModal;
