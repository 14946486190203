import React, { useContext } from "react";
import { PackageDetails } from "../constants";
import { Box, Stack } from "@mui/material";
import LocaleContext from "@/contexts/LocaleContext";
import ScalersTypography from "@/components/atoms/typography/typography";
import { t } from "i18next";
import DoneIcon from "@mui/icons-material/Done";
import { LoadingButton } from "@mui/lab";
import monthlyPlan from "@/../static/images/monthly-plan.png";
import quarterPlan from "@/../static/images/quarter-plan.png";
import axiosInstance from "@/../static/axios";
import { useNavigate } from "react-router-dom";
import {
    ProductSubscriptionType,
    PaymentTransactionMethod,
} from "../constants";

type TabPanelProps = {
    children?: React.ReactNode;
    index: number;
    value: number;
};

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`app-subscription-tabpanel-${index}`}
            aria-labelledby={`app-subscription-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className="tab-content-container">{children}</Box>
            )}
        </Box>
    );
}

interface AppSubscriptionTabPanelProps {
    subscriptionTabsContent: PackageDetails[];
    value: number;
}

const subscriptionIconMap: Record<"1" | "3", JSX.Element> = {
    "1": (
        <img
            src={monthlyPlan}
            alt="monthly-plan-icon"
            className="monthly-plan-icon"
        />
    ),
    "3": (
        <img
            src={quarterPlan}
            alt="quarter-plan-icon"
            className="quarter-plan-icon"
        />
    ),
};

function AppSubscriptionTabPanel({
    value,
    subscriptionTabsContent,
}: AppSubscriptionTabPanelProps) {
    const { locale } = useContext(LocaleContext);
    const selectedContent = subscriptionTabsContent[value];
    const highlights = subscriptionTabsContent[value][`includes_${locale}`];
    const planName = selectedContent[`name_${locale}`];
    const planTabName = t(
        `app-subscription-modal.months.${selectedContent.duration_months}`
    );
    const navigate = useNavigate();
    const planIcon =
        subscriptionIconMap[
            String(selectedContent.duration_months) as "1" | "3"
        ];
    const handleBuySubscription = async (packageId: string) => {
        const res = await axiosInstance.post("/payments/intents", {
            package: packageId,
            product: ProductSubscriptionType.HP_SUBSCRIPTION,
            method: PaymentTransactionMethod.MOYASAR,
        });
        if (res.data.success) {
            navigate(`/hp/subscribe/${res.data.data.payment_intent}`);
        }
    };
    return subscriptionTabsContent.map(
        (content: PackageDetails, index: number) => {
            const isDiscountApplicable = selectedContent?.monthly_discount;
            return (
                <CustomTabPanel key={content.id} value={value} index={index}>
                    <Stack className="subscription-details">
                        <Box
                            display="flex"
                            gap="8px"
                            width="220px"
                            className="subscription-name"
                        >
                            <Box className="subscription-icon-container">
                                {planIcon}
                            </Box>
                            <Stack gap="4px">
                                <ScalersTypography
                                    variant="body2"
                                    color="var(--black-400)"
                                    fontSize="13px"
                                >
                                    {planName}
                                </ScalersTypography>
                                <ScalersTypography
                                    variant="h6"
                                    fontSize="23px"
                                    fontWeight="700"
                                >
                                    {planTabName}
                                </ScalersTypography>
                            </Stack>
                        </Box>
                        <Stack
                            gap="4px"
                            marginTop="auto"
                            className="subscription-pricing"
                        >
                            {selectedContent.is_popular && (
                                <ScalersTypography
                                    variant="body1"
                                    fontSize="12px"
                                    fontWeight="700"
                                >
                                    🔥
                                    <span className="linear-gradient-text">
                                        {t("app-subscription-modal.popular")}
                                    </span>
                                </ScalersTypography>
                            )}
                            {isDiscountApplicable && (
                                <ScalersTypography
                                    variant="body1"
                                    className="original-plan-price"
                                >
                                    {selectedContent?.monthly_price}
                                </ScalersTypography>
                            )}
                            <Stack>
                                <ScalersTypography
                                    variant="body1"
                                    fontSize="30px"
                                    fontWeight="700"
                                >
                                    {`SAR ${
                                        isDiscountApplicable
                                            ? selectedContent?.discountedPrice
                                            : selectedContent?.monthly_price
                                    }`}
                                </ScalersTypography>
                                <ScalersTypography
                                    variant="body1"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="var(--black-400)"
                                >
                                    {t(
                                        `app-subscription-modal.month-payment.${selectedContent.duration_months}`
                                    )}
                                </ScalersTypography>
                            </Stack>
                            <Stack gap="2px">
                                <LoadingButton
                                    onClick={() =>
                                        handleBuySubscription(content.id)
                                    }
                                    variant="contained"
                                    className={`pay-btn ${isDiscountApplicable ? "linear-gradient-btn" : ""}`}
                                >
                                    {t("app-subscription-modal.pay-btn")}
                                </LoadingButton>
                                <ScalersTypography
                                    variant="caption"
                                    fontSize="11px"
                                    fontWeight="400"
                                    color="var(--black-200)"
                                >
                                    {t(
                                        "app-subscription-modal.vat-not-included"
                                    )}
                                </ScalersTypography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack className="subscription-highlights">
                        <ScalersTypography
                            variant="body2"
                            fontSize="13px"
                            fontWeight="400"
                        >
                            {t("app-subscription-modal.subscription-includes")}
                        </ScalersTypography>
                        {React.Children.map(highlights, (highlight) => {
                            return (
                                <Box className="highlight">
                                    <DoneIcon className="tick-icon" />
                                    <ScalersTypography
                                        variant="body1"
                                        fontSize="16px"
                                        fontWeight="500"
                                        color="var(--black-400)"
                                        dangerouslySetInnerHTML={{
                                            __html: highlight || "",
                                        }}
                                    />
                                </Box>
                            );
                        })}
                    </Stack>
                </CustomTabPanel>
            );
        }
    );
}

export default AppSubscriptionTabPanel;
