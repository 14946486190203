import React, { memo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUser } from "@/contexts/UserContext";
import singletonLocalStorage from "@/utils/localStoring";
import { CurrUserDetails } from "@/utils/currUserType";
import { ExtraTracker } from "@/mixpanel-tracking/hp/extra-trackers";
import { Factory, Pages } from "@/mixpanel-tracking/hp/factory";
import { freeTrialEnded } from "@/utils/utilFunctions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootStateOrAny } from "react-redux";
import { setAppSubscriptionModalActive } from "@/core/stores/general/general.store";
import { Box, IconButton, Modal, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AppSubscription from "@/components/AppSubscription/AppSubscription";
import { FeatureFlag } from "@/feature-flag";
import { SubscriptionTracker } from "@/mixpanel-tracking/hp/subscription-tracking";

type Props = {
    locale: string;
    children: React.ReactNode;
};

const AppSubscriptionModalWrapper = ({ currUser }: { currUser: object }) => {
    const { appSubscriptionModalActive } = useSelector(
        (state: RootStateOrAny) => state.general
    );

    const dispatch = useDispatch();
    const factory = new Factory({
        currUser,
    });
    const subscriptionTracker = factory.get_tracker(
        Pages.SUBSCRIPTIONS
    ) as SubscriptionTracker;

    const handleAppSubscriptionModalClose = () => {
        dispatch(setAppSubscriptionModalActive(false));
        subscriptionTracker.pricing_Info_viewed_exited();
    };
    return (
        <Modal
            className="app-subscription-modal"
            open={appSubscriptionModalActive}
            onClose={handleAppSubscriptionModalClose}
        >
            <Stack className="modal-container">
                <Box className="close-btn">
                    <IconButton
                        aria-label="close"
                        onClick={handleAppSubscriptionModalClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <AppSubscription from="Pricing Info Pop up" />
            </Stack>
        </Modal>
    );
};

const RequireSubscription: React.FC<Props> = memo(({ locale, children }) => {
    const currUser: CurrUserDetails = getUser() as CurrUserDetails;
    const factory = new Factory();
    const track = factory.get_tracker(Pages.EXTRA_TRACKER) as ExtraTracker;
    const location = useLocation();
    const navigate = useNavigate();

    if (!FeatureFlag?.enable_buy_subscription() && freeTrialEnded(currUser)) {
        if (currUser.company) {
            track.block_page_redirect({
                free_trial: currUser.company.free_trial,
                free_trial_end_date: currUser.company.access_end_date,
                from: location.pathname,
                ...currUser,
            });
        }
        singletonLocalStorage.setItem("redirected", "true");
        singletonLocalStorage.removeItem("access_token");

        setTimeout(() =>
            navigate("/book-call", {
                replace: true,
                state: { from: location, user: currUser, locale: locale },
            })
        );
    }

    useEffect(() => {
        singletonLocalStorage.removeItem("skipFreeTrialRedirect");
    }, []);

    return (
        <>
            <AppSubscriptionModalWrapper currUser={currUser} />
            {children}
        </>
    );
});

RequireSubscription.displayName = "RequireSubscription";
export default RequireSubscription;
