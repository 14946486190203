import ScalersTypography from "@/components/atoms/typography/typography";
import { Tab, Tabs } from "@mui/material";
import React, { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { SubscriptionData } from "../constants";

function a11yProps(id: string) {
    return {
        id: `app-subscription-tab-${id}`,
        "aria-controls": `app-subscription-tabpanel-${id}`,
    };
}

interface AppSubscriptionTabsProps {
    subscriptionTabs: SubscriptionData[];
    value: number;
    handleChange: (_: SyntheticEvent, newValue: number) => void;
}

function AppSubscriptionTabs({
    subscriptionTabs,
    value,
    handleChange,
}: AppSubscriptionTabsProps) {
    const { t } = useTranslation();
    return (
        <Tabs
            classes={{
                flexContainer: "tabs",
                indicator: "selected-tab-indicator",
            }}
            value={value}
            onChange={handleChange}
        >
            {subscriptionTabs?.map((tab: SubscriptionData, index: number) => {
                const tabText = t(
                    `app-subscription-modal.months.${tab.tabText}`
                );
                let extraTabText = null;
                let selectedTextClass =
                    value === index ? "selected-tab-text" : "tab-text";
                if (tab.discountPercentage) {
                    extraTabText = ` ${t(
                        "app-subscription-modal.months.saving",
                        {
                            discountPercentage: tab.discountPercentage,
                        }
                    )}`;
                    if (selectedTextClass !== "tab-text") {
                        selectedTextClass += " linear-gradient-text";
                    }
                }
                const tabTextComponent = (
                    <ScalersTypography
                        variant="body1"
                        fontSize="16px"
                        fontWeight="700"
                        className={selectedTextClass}
                    >
                        {tabText}
                        <span className="extra-tab-text linear-gradient-text">
                            {extraTabText}
                        </span>
                    </ScalersTypography>
                );
                return (
                    <Tab
                        key={tab.id}
                        label={tabTextComponent}
                        classes={{
                            root: "tab-btn",
                            selected: "selected-tab-btn",
                        }}
                        {...a11yProps(tab.id)}
                    />
                );
            })}
        </Tabs>
    );
}

export default AppSubscriptionTabs;
