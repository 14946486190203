import { CandidateDetailsTrack } from "./candidate-details-tracking";
import { CandidateListTrack } from "./candidate-list-tracking";
import { CuratedListTracking } from "./curated-list-tracking";
import { ExtraTracker } from "./extra-trackers";
import { SettingsTracker } from "./settings-tracker";
import { JobFormTracking } from "./job-form-tracking";
import { JobChangeTracking } from "./job-change-actions";
import { MessagesTracking } from "./messages-tracking";
import { PipelineTrack } from "./pipeline-tracking";
import { JobApplicantsTracking } from "./job-applicants-tracker";
import { LoginLogOutSignUpTracking } from "./login-logout-signup-tracker";
import { SignupWizardTracker } from "./signup-wizard-tracking";
import { SignUpRework } from "./signup-rework-tracking";
import {
    NotificationBellTracking,
    NotificationItemTracking,
    NotificationSettingsTracking,
} from "./notification-tracking";
import { ExperimentTracker } from "@/mixpanel-tracking/hp/experiment-tracker";
import { InterviewTracking } from "./interview-tracking";
import { WaitingListTracker } from "./waitinglist/waiting-list-tracker";
import { NotServedJtbdTracking } from "./not-served-jtbd-viewd-tracking";
import { JobDistributionHPTracking } from "./job-distribution";
import { SubscriptionTracker } from "./subscription-tracking";

export const Pages = {
    PIPELINE: 1,
    CANDIDATE_DETAILS: 2,
    CANDIDATE_LIST: 3,
    MESSAGES: 4,
    CURATED_LIST: 5,
    JOB_CHANGE: 6,
    EXTRA_TRACKER: 7,
    SETTINGS: 8,
    JOB_FORM: 9,
    APPLICANTS_LIST: 10,
    LOGIN_LOGOUT_SIGNUP: 11,
    NOTIFICATION_BELL: 12,
    NOTIFICATION_ITEM: 13,
    EXPERIMENT_TRACKER: 14,
    NOTIFICATION_SETTINGS: 15,
    SIGNUP_WIZARD: 16,
    INTERVIEW_SCHEDULING: 17,
    WAITING_LIST: 18,
    NOT_SERVED_JTBD: 19,
    SIGN_UP_REWORK: 20,
    SUBSCRIPTIONS: 21,
    JOB_DISTRIBUTION: 22,
};

export class Factory {
    constructor(constant_props) {
        this.constant_props = constant_props;
    }
    get_tracker = (page) => {
        switch (page) {
            case Pages.PIPELINE:
                return new PipelineTrack(this.constant_props);
            case Pages.CANDIDATE_DETAILS:
                return new CandidateDetailsTrack(this.constant_props);
            case Pages.CANDIDATE_LIST:
                return new CandidateListTrack(this.constant_props);
            case Pages.MESSAGES:
                return new MessagesTracking(this.constant_props);
            case Pages.CURATED_LIST:
                return new CuratedListTracking(this.constant_props);
            case Pages.JOB_CHANGE:
                return new JobChangeTracking(this.constant_props);
            case Pages.JOB_FORM:
                return new JobFormTracking(this.constant_props);
            case Pages.SETTINGS:
                return new SettingsTracker(this.constant_props);
            case Pages.EXTRA_TRACKER:
                return new ExtraTracker(this.constant_props);
            case Pages.APPLICANTS_LIST:
                return new JobApplicantsTracking(this.constant_props);
            case Pages.LOGIN_LOGOUT_SIGNUP:
                return new LoginLogOutSignUpTracking(this.constant_props);
            case Pages.NOTIFICATION_BELL:
                return new NotificationBellTracking(this.constant_props);
            case Pages.NOTIFICATION_ITEM:
                return new NotificationItemTracking(this.constant_props);
            case Pages.EXPERIMENT_TRACKER:
                return new ExperimentTracker(this.constant_props);
            case Pages.NOTIFICATION_SETTINGS:
                return new NotificationSettingsTracking(this.constant_props);
            case Pages.INTERVIEW_SCHEDULING:
                return new InterviewTracking(this.constant_props);
            case Pages.SIGNUP_WIZARD:
                return new SignupWizardTracker(this.constant_props);
            case Pages.WAITING_LIST:
                return new WaitingListTracker(this.constant_props);
            case Pages.NOT_SERVED_JTBD:
                return new NotServedJtbdTracking(this.constant_props);
            case Pages.SIGN_UP_REWORK:
                return new SignUpRework(this.constant_props);
            case Pages.SUBSCRIPTIONS:
                return new SubscriptionTracker(this.constant_props);
            case Pages.JOB_DISTRIBUTION:
                return new JobDistributionHPTracking(this.constant_props);
        }
    };
}
