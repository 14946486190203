import ScalersTypography from "@/components/atoms/typography/typography";
import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

function BookCallFooter({
    handleCalendlyModal,
}: {
    handleCalendlyModal: () => void;
}) {
    const { t } = useTranslation();
    return (
        <Box className="book-call-footer">
            <ScalersTypography
                component="span"
                variant="body1"
                fontSize="13px"
                fontWeight="500"
            >
                {t("app-subscription-modal.book-call.text")}
            </ScalersTypography>{" "}
            <ScalersTypography
                component="span"
                variant="body1"
                className="click-book-call"
                onClick={handleCalendlyModal}
            >
                {t("app-subscription-modal.book-call.btn-text")}
            </ScalersTypography>
        </Box>
    );
}

export default BookCallFooter;
